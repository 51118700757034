import React from "react";
import { AUDIO_URL, Track } from "./common"
import "./DownloadControl.css"

export type DownloadControlProps = {
    tracks: Track[];
    selectedTrack: Track;
    visible: boolean;
    onClosed(): void;
}

type DownloadControlState = {

}

export default class DownloadControl extends React.Component<DownloadControlProps, DownloadControlState> {
    containerRef: React.RefObject<HTMLDivElement>;
    first: boolean;
    constructor(props: DownloadControlProps) {
        super(props);
        this.state = {};
        this.containerRef = React.createRef();
        this.onDonloadPlaylist = this.onDonloadPlaylist.bind(this);
        this.onDownloadSong = this.onDownloadSong.bind(this);
        this.onClick = this.onClick.bind(this);
        this.first = true;
    }
    componentDidMount(): void {
        if (this.containerRef.current != null) {
            this.containerRef.current.style.display = this.props.visible ? "flex" : "none";
        }
    }
    componentDidUpdate(prevProps: Readonly<DownloadControlProps>, prevState: Readonly<DownloadControlState>, snapshot?: any): void {
        if (prevProps.visible !== this.props.visible && this.containerRef.current != null) {
            this.containerRef.current.style.display = this.props.visible ? "flex" : "none";
            if (this.props.visible) {
                this.first = true;
                window.addEventListener("click", this.onClick);
            }
            else {
                window.removeEventListener("click", this.onClick);
            }
        }
    }
    componentWillUnmount(): void {
        window.removeEventListener("click", this.onClick);
        window.removeEventListener("touchstart", this.onClick);
    }
    onDonloadPlaylist() {
        var m3u = '#EXTM3U\r\n#EXTENC: UTF-8\r\n';
        var location = AUDIO_URL;
        this.props.tracks.forEach(element => {
            m3u += location + element.file + '.mp3\r\n';
        });

        var blob = new Blob([m3u], { type: "text/plain;charset=utf-8" });
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "playlist.m3u";
        a.click();
        this.close();
    }
    onDownloadSong() {
        var location = AUDIO_URL + this.props.selectedTrack.file + ".mp3";
        var a = document.createElement("a");
        a.href = location;
        a.download = this.props.selectedTrack.file + ".mp3";
        a.click();
        this.close();
    }
    close() {
        if (this.containerRef.current != null) {
            this.containerRef.current.style.display = "none";
        }

        this.props.onClosed();
    }
    onClick(e: MouseEvent | TouchEvent) {
        if (this.containerRef.current == null || !this.props.visible || this.first) {
            this.first = false;
            return;
        }       
        
        var target: EventTarget | any = e.target;
        if (!this.containerRef.current.contains(target)) {
            this.close();
        }
    }
    render(): JSX.Element {
        return <div id="downloadContainer" ref={this.containerRef}>
            <div id="downloadPlaylist" className="downloadLink" onClick={() => this.onDonloadPlaylist()}>
                <div className="link">Download playlist</div>
            </div>
            <div id="downloadSong" className="downloadLink" onClick={() => this.onDownloadSong()}>
                <div className="link">Download song</div>
            </div>
        </div>
    }
}