import React from 'react'
import './TitleBar.css'
import { Track } from './common'

export type TitleControlProps = {
    track: Track;
    visible: boolean;
    onTogglePlaylist(): void;
    onToggleMenu(): void;
}

export type TitleBarState = {
    sizeIndex: number;
}

function GetTextClasse(index: number): string {
    switch (index) {
        case 0:
            return 'text-center text-xs text-white';

            case 1:
            return 'text-center text-sm text-white';

            case 2:
            return 'text-center text-base text-white';

            case 3:
            return 'text-center text-lg text-white';

            case 4:
            return 'text-center text-xl text-white';

            case 5:
            return 'text-center text-2xl text-white';

            default:
            return 'text-center text-lg text-white';
    }
}

export class TitleBar extends React.Component<TitleControlProps, TitleBarState> {
    private _ref: React.RefObject<HTMLDivElement>;
    constructor(pros: TitleControlProps) {
        super(pros);
        this.state = {
            sizeIndex: 4
        }

        this._ref = React.createRef();
    }

    public get titleClass(): string {
        return GetTextClasse(this.state.sizeIndex + 1);    
    }

    public get bandClass(): string {
        return GetTextClasse(this.state.sizeIndex);
    }

    public get sizeIndex(): number {
        return this.state.sizeIndex;
    }

    private set sizeIndex(value: number) {
        this.setState({ sizeIndex: value });
    }

    componentDidMount(): void {
        const element = this._ref.current;
        if (element != null) {
            if (element.scrollHeight > element.clientHeight) {
                if (this.state.sizeIndex > 0) {
                    this.setState({ sizeIndex: this.state.sizeIndex - 1 });
                }           
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<TitleControlProps>, prevState: Readonly<TitleBarState>, snapshot?: any): void {
        const element = this._ref.current;
        if (element != null) {
            if (element.scrollHeight > element.clientHeight) {
                if (this.state.sizeIndex > 0) {
                    this.setState({ sizeIndex: this.state.sizeIndex - 1 });
                }           
            }
        }
    }

   
    trackName(): string {
        return (this.props.track != null ? this.props.track.name : 'unknown');
    }
    trackArtist(): string {
        return this.props.track != null ? this.props.track.original_artist : 'unknown';
    }

    text(): JSX.Element {
        return <pre className='text-center'>            
            <b className={this.titleClass}><u>{this.trackName()}</u></b>
            {'\r\n\r\n'}
            <b className={this.bandClass}>({this.trackArtist()})</b>
        </pre>
    }
    
    render(): JSX.Element {
        return (<div id='titleContainer' className='overflow-auto no-scrollbar'>
            <div ref={this._ref} id='titleDisplay' className={this.props.visible ? '' : 'hidden'}>
                {this.text()}        
            </div>
        </div>)
    }
}