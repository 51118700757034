import { SortTracks, SortType, Track } from "./common";

export enum EventType {
    TracksChanged = 0,
    SelectedChanged = 1,    
}

export type TrackFilter = {
    Originals: boolean;
    Covers: boolean;
}

export interface IPlaylist {
    readonly Tracks: Track[];
    readonly Filter: TrackFilter;
    readonly Order: SortType;

    OnTracksChanged?(tracks: Track[] | null): void;

    SetTracks(tracks: Track[]): void;
    SetSortOrder(order: SortType): void;
    SetTrackFilter(filter: TrackFilter): void;
}

class Playlist implements IPlaylist {
    private _tracks: Track[] = [];
    private _original: Track[] | null = null;
    private _order: SortType = SortType.None;
    private _filter: TrackFilter;

    public get Tracks(): Track[]
    {
        return this._tracks;
    }    

    public get Filter(): TrackFilter {
        return this._filter;
    }

    public get Order() : SortType {
        return this._order;
    }

    constructor() {
        this._filter = {
            Covers: true,
            Originals: true,
        };
    }

    OnTracksChanged?(tracks: Track[] | null): void;

    SetTracks(tracks: Track[]): void {
        this._original = tracks;
        this.SetSortOrder(this._order);        
    }

    SetSortOrder(order: SortType): void {
        if (this._original == null) {
            return;
        }
        
        this._order = order;
        this._tracks = this.FilteredTracks();
        if (order !== SortType.None) {
            SortTracks(this._tracks, order);
        }        
        
        this.OnTracksChanged?.(this._tracks);
        this.Save();
    }

    SetTrackFilter(filter: TrackFilter): void {
        this._filter = filter;
        this.SetSortOrder(this._order);        
    }

    FilteredTracks() : Track[] {
        if (this._original == null) {
            return [];
        }

        let result = [];
        for (let track of this._original) {
            if (track.original && this._filter.Originals) {
                result.push(track);
            }
            else if (!track.original && this._filter.Covers) {
                result.push(track);
            }
        }

        return result;
    }

    Save() : void {
        let settings: PlaylistSettings = {
            Order: this._order,
            Filter: this.Filter
        }
        
        document.cookie = JSON.stringify(settings);
    }

    Load() : void {
        if (document.cookie == null) {
            return;
        }

        try {
            let settings: PlaylistSettings = JSON.parse(document.cookie);
            if (settings != null) {
                this._order = settings.Order;
                this.SetTrackFilter(settings.Filter);
            }
        }
        catch (err) {

        }
    }
}

export interface PlaylistSettings {
    Order: SortType;
    Filter: TrackFilter;
}

const playlist = new Playlist();
playlist.Load();
export default function PlaylistManager() : IPlaylist {
    return playlist;
}