import React from 'react';
import { MediaState, RepeatState } from './common';
import { VolumeControl } from './VolumeControl';
import { subscribe, unsubscribe } from './events';
import styles from './MediaControl.module.css';

export type MediaControlState = {
    state: MediaState,
    repeat: RepeatState,
    volume: number,
    progress: number,
    random: boolean,
    volumVisible: boolean
}

export interface MediaControlProps {
    onIndexChanged(index: number): void
    onMediaStateChanged(value: MediaState): void
    onRepeatStateChanged(value: RepeatState): void
    onVolumeChanged(value: number): void
    onPlayRandomChanged(value: boolean): void
    onTogglePlaylist(): void
    mediaState: MediaState
}

export default class MediaControl extends React.Component<MediaControlProps, MediaControlState> {
    constructor(props: MediaControlProps) {
        super(props);
        this.state = {
            state: props.mediaState,
            volume: 100,
            progress: 0,
            repeat: RepeatState.Repeat,
            random: false,
            volumVisible: false
        }
    }
    public get MediaState(): MediaState {
        return this.state.state;
    }
    public set MediaState(value: MediaState) {
        this.setState(() => {
            return { state: value };
        })

        this.props.onMediaStateChanged(value);
    }
    public get RepeatState(): RepeatState {
        return this.state.repeat;
    }
    public set RepeatState(value: RepeatState) {
        this.setState(() => {
            return { repeat: value }
        })

        this.props.onRepeatStateChanged(value);
    }
    public get Random(): boolean {
        return this.state.random;
    }
    public set Random(value: boolean) {
        if (this.state.random === value) {
            return;
        }

        this.setState(() => {
            return { random: value }
        });

        this.props.onPlayRandomChanged(value);
    }

    public get VolumVisible(): boolean {
        return this.state.volumVisible;
    }

    public set VolumVisible(value: boolean) {
        if (this.VolumVisible === value) {
            return;
        }

        this.setState(() => {
            return { volumVisible: value };
        })
    }

    componentDidMount(): void {
        this.nextRepeatState = this.nextRepeatState.bind(this);
        this.toggleVolume = this.toggleVolume.bind(this);
        this.setVolumeVisible = this.setVolumeVisible.bind(this);
        this.volumeChanged = this.volumeChanged.bind(this);
        this.playstateChanged = this.playstateChanged.bind(this);
        subscribe("playstateChanged", this.playstateChanged);
    }

    componentWillUnmount() {
        unsubscribe("playstateChanged", this.playstateChanged);
    }

    playstateChanged(e: CustomEvent): void {
        this.MediaState = e.detail;
    }

    nextMediaState() {
        switch (this.MediaState) {
            case MediaState.Stopped:
            case MediaState.Paused:
                this.MediaState = MediaState.Playing;
                break;

            case MediaState.Playing:
                this.MediaState = MediaState.Paused;
                break;
        }
    }

    nextRepeatState() {
        switch (this.RepeatState) {
            case RepeatState.None:
                this.RepeatState = RepeatState.Repeat;
                break
            case RepeatState.Repeat:
                this.RepeatState = RepeatState.RepeatOne;
                break
            case RepeatState.RepeatOne:
                this.RepeatState = RepeatState.None;
                break
        }
    }
    skip(value: number) {
        if (this.props.onIndexChanged == null) {
            return;
        }

        this.props.onIndexChanged(value);
    }

    toggleVolume() {
        this.VolumVisible = !this.VolumVisible;
    }

    togglePlayPause() {
        if (this.MediaState === MediaState.Playing) {
            this.MediaState = MediaState.Paused;
        }
        else {
            this.MediaState = MediaState.Playing;
        }
    }

    volumeChanged(value: number): void {
        this.setState(() => {
            return { volume: value }
        });

        this.props.onVolumeChanged(value);
    }

    setVolumeVisible(value: boolean): void {
        this.VolumVisible = value;
    }

    renderPlayPause(): JSX.Element {
        if (this.state.state === MediaState.Stopped || this.state.state === MediaState.Paused) {
            return <div id={styles.mediaPlay} className={styles.mediaButton} onClick={() => { this.nextMediaState() }} title="Play"></div>
        }
        if (this.state.state === MediaState.Playing) {
            return <div id={styles.mediaPause} className={styles.mediaButton} onClick={() => { this.nextMediaState() }} title="Pause"></div>
        }
        return <div id={styles.mediaLoading} className={styles.mediaButton} onClick={() => { this.nextMediaState() }} title="Loading"></div>
    }

    renderRepeat(): JSX.Element {
        if (this.RepeatState === RepeatState.None) {
            return <div id={styles.mediaRepeatNone} className={`${styles.mediaButton} ${styles.disabled}`} onClick={() => { this.nextRepeatState() }} title="Repeat off"></div>
        }
        if (this.RepeatState === RepeatState.Repeat) {
            return <div id={styles.mediaRepeatAll} className={styles.mediaButton} onClick={() => { this.nextRepeatState() }} title="Repeat all"></div>
        }

        return <div id={styles.mediaRepeatOne} className={styles.mediaButton} onClick={() => { this.nextRepeatState() }} title="Repeat one"></div>
    }
    renderRandom(): JSX.Element {
        if (this.Random) {
            return <div id={styles.mediaRandom} className={styles.mediaButton} onClick={() => { this.Random = !this.Random }} title="Shuffle on"></div>
        }
        return <div id={styles.mediaRandom} className={`${styles.mediaButton} ${styles.disabled}`} onClick={() => { this.Random = !this.Random }} title="Shuffle off"></div>
    }

    render(): JSX.Element {
        return (<div id={styles.mediaContainer}>
            <div id={styles.mediaController}>
            <div id={styles.menuButton} className={styles.mediaButton} onClick={() => this.props.onTogglePlaylist()} />
                {this.renderRandom()}
                <div id={styles.mediaPrevious} className={styles.mediaButton} onClick={() => { this.skip(-1) }} title="Previous track"></div>
                {this.renderPlayPause()}
                <div id={styles.mediaNext} className={styles.mediaButton} onClick={() => { this.skip(1) }} title="Next track"></div>
                {this.renderRepeat()}
                <div id={styles.mediaVolume} className={styles.mediaButton} onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.toggleVolume();
                }} title="Volume">
                    <VolumeControl visible={this.state.volumVisible} volume={this.state.volume} button={document.getElementById(styles.mediaVolume)} onVolumeChanged={(value) => this.volumeChanged(value)} onVisibilityChanged={(value) => { this.setVolumeVisible(value) }}>
                    </VolumeControl>
                </div>
            </div>
        </div>);
    }
}