import React, { Fragment, useEffect } from "react";
import Select from "react-select";
import { Track, SortType, AUDIO_URL } from "./common";
//import "./PlaylistControl.css"
import playlistItem from './PlaylistItem.module.css';
import styles from './Playlist.module.css'
import PlaylistManager, { PlaylistSettings, TrackFilter } from "./Playalist";

export type PlaylistControlProps = {
    selectedIndex: number;
    orderBy: SortType;
    visible: boolean;
    onOrderChanged(value: SortType): void;
    onSelectedTrackChanged(value: Track): void;
    getTracks(): Track[];
}

export type PlaylistControlState = {
    tracks: Track[];
    orderBy: SortType;
    focused: number;
    filter: number;
}

interface SordOption {
    value: SortType,
    label: string
}

interface FilterOption {
    value: number,
    label: string
}

const options: SordOption[] = [
    { value: SortType.None, label: 'default' },
    { value: SortType.Name, label: 'Name ↓' },
    { value: SortType.NameDescending, label: 'Name ↑' },
    { value: SortType.OriginalArtist, label: 'Original Artist ↓' },
    { value: SortType.OriginalArtistDecending, label: 'Original Artist ↑' },
]

const filterOptions: FilterOption[] = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Originals' },
    { value: 2, label: 'Covers' }
]

export default class PlaylistControl extends React.Component<PlaylistControlProps, PlaylistControlState> {
    containerRef: React.RefObject<HTMLDivElement>;
    updateFocus: boolean = false;
    cachedIndex: number = 0;
    constructor(props: PlaylistControlProps) {
        super(props);
        this.state = {
            tracks: props.getTracks(),
            orderBy: props.orderBy,
            focused: 0,
            filter: 0
        }

        this.containerRef = React.createRef();
    }

    public get orderBy(): SortType {
        return this.state.orderBy;
    }

    private set orderBy(value: SortType) {
        // if (this.orderBy === value) {
        //     return;
        // }

        PlaylistManager().SetSortOrder(value);
        this.setState(() => {
            return {
                orderBy: value,
                tracks: PlaylistManager().Tracks.slice()
            }
        })

        this.props.onOrderChanged(value);
    }

    public get SelectedOption(): SordOption {
        for (let i = 0; i < options.length; i++) {
            if (options[i].value === this.state.orderBy) {
                return options[i];
            }
        }

        return options[0];
    }

    public get SelectedFilter(): FilterOption {
        let playlist = PlaylistManager();
        let filter = playlist.Filter;
        if (filter.Covers && filter.Originals) {
            return filterOptions[0];
        }

        if (filter.Originals) {
            return filterOptions[1];
        }

        return filterOptions[2];
    }

    componentDidMount(): void {
        if (this.containerRef.current != null) {
            this.containerRef.current.style.display = (this.props.visible ? "block" : "none");
        }

        this.updateFocus = true;
        this.cachedIndex = this.props.selectedIndex;
        this.setState(() => {
            return { focused: -1 }
        });

        let self = this;
        setTimeout(() =>{
            if (document.cookie == null) {
                return;
            }
    
            try {
                let settings: PlaylistSettings = JSON.parse(document.cookie);
                if (settings != null) {                    
                    self.orderBy = settings.Order;
                    PlaylistManager().SetTrackFilter(settings.Filter);
                }
            }
            catch (err) {
    
            }
        }, 1000)
    }

    componentDidUpdate(prevProps: Readonly<PlaylistControlProps>, prevState: Readonly<PlaylistControlState>, snapshot?: any): void {
        if (prevProps.visible !== this.props.visible && this.containerRef.current != null) {

            this.containerRef.current.style.display = (this.props.visible ? "block" : "none");
            var selection = window.getSelection();
            if (selection != null) {
                selection.removeAllRanges();
            }

            this.updateFocus = true;
            this.cachedIndex = this.props.selectedIndex;
            this.setState(() => {
                return { focused: -1 }
            });
        }

        if (this.updateFocus) {
            this.updateFocus = false;
            this.setState(() => {
                return { focused: this.cachedIndex }
            });
        }

        if (prevProps.selectedIndex !== this.props.selectedIndex) {
            this.setState(() => {
                return { focused: this.props.selectedIndex }
            });
        }

        if (prevState.orderBy !== this.state.orderBy || prevState.filter !== this.state.filter) {
            this.setState(() => {
                return { focused: 0 };
            })
        }
    }

    setOrderBy(value: SortType): void {
        this.orderBy = value;
    }

    downloadTrack(value: Track): void {
        var location = AUDIO_URL + value.file + ".mp3";
        var a = document.createElement("a");
        a.href = location;
        a.download = value.file + ".mp3";
        a.target = 'blank';
        a.click();
    }

    downloadPlaylist() {
        var m3u = '#EXTM3U\r\n#EXTENC: UTF-8\r\n';
        var location = AUDIO_URL;
        let tracks = PlaylistManager().Tracks;
        tracks.forEach(element => {
            m3u += location + element.file + '.mp3\r\n';
        });

        var blob = new Blob([m3u], { type: "text/plain;charset=utf-8" });
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "playlist.m3u";
        a.click();
    }

    shareTrack(value: Track) : void {
        var url = new URL(document.location.href);
        var location = url.origin + '?id=' + value.id;
        navigator.clipboard.writeText(location);
    }

    render(): JSX.Element {

        if (this.state.tracks == null || this.state.tracks.length <= 0) {
            return <div id={styles.playlistContainer} ref={this.containerRef}>no tracks</div>
        }

        return <div id={styles.playlistContainer} ref={this.containerRef}>
            <div id={styles.playlistToolbar}>
                {/* <label className={`${styles.toolbarLabel} ${styles.item}`}>Order by</label>*/}
                <Select
                    className={styles.item}
                    value={this.SelectedOption}
                    options={options}
                    defaultValue={options[0]}
                    onChange={(value: SordOption | any) => this.setOrderBy(value.value)}
                />
                {/*<label className={`${styles.toolbarLabel} ${styles.item}`}>Show</label>*/}
                <Select
                    className={styles.item}
                    value={this.SelectedFilter}
                    options={filterOptions}
                    onChange={(value: FilterOption | any) => {
                        if (value == null) {
                            return;
                        }

                        let filter: TrackFilter = {
                            Originals: false,
                            Covers: false
                        }

                        switch (value.value) {
                            case 0:
                                filter.Covers = true;
                                filter.Originals = true;
                                break;

                            case 1:
                                filter.Originals = true;
                                break;

                            case 2:
                                filter.Covers = true;
                                break;
                        }

                        PlaylistManager().SetTrackFilter(filter);
                        this.setState(() => {
                            return { 
                                filter: value.value
                            };
                        })
                    }}
                />
                <span className={styles.download} title="Download playlist" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.downloadPlaylist();
                }}></span>
            </div>
            {
                this.state.tracks.map((element, i) => (
                    <Fragment key={element.id}>
                        <PlaylistItem
                            onTrackSelected={() => this.props.onSelectedTrackChanged(element)}
                            selected={i === this.props.selectedIndex}
                            focused={i === this.state.focused}
                            index={i}
                            track={element}
                            onDownloadTrack={this.downloadTrack}
                            onShareTrack={this.shareTrack}
                            ></PlaylistItem>
                    </Fragment>
                ))
            }
        </div>
    }
}

type PlaylistItemProps = {
    track: Track;
    selected: boolean;
    focused: boolean;
    index: number;
    onTrackSelected(value: Track): void;
    onDownloadTrack(value: Track): void;
    onShareTrack(value: Track): void;
}

function PlaylistItem(props: PlaylistItemProps): JSX.Element {
    const reference: React.RefObject<HTMLDivElement> = React.createRef();
    useEffect(() => {
        if (props.focused && reference.current != null && props.track.id !== 1) {
            reference.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
    })

    return <div ref={reference}
        className={`${playlistItem.container} ${props.selected ? playlistItem.selected : ''}`}
        onClick={() => props.onTrackSelected(props.track)}>
        <span className={playlistItem.image}></span>
        <span className={playlistItem.info}>
            <label className={playlistItem.label}>{props.track.name}</label>
            <label className={playlistItem.label}>{props.track.original_artist}</label>
        </span>
        <span className={playlistItem.flowControl}>
            <span className={playlistItem.flow}></span>
            <span className={playlistItem.download} title="Download mp3" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.onDownloadTrack(props.track);
            }}>
            </span>
            <span className={playlistItem.share} title="Copy link url to clipboard" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.onShareTrack(props.track);
            }}></span>
            <span className={playlistItem.flow}></span>
        </span>
    </div>
}