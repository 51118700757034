import React from 'react'
import ReactDOM from 'react-dom';
import './VolumeControl.css'
import media from './MediaControl.module.css';

const modal = document.getElementById('modal');
const clamp = (num: number, min: number, max: number): number => Math.min(Math.max(num, min), max);

export type VolumeControlProps = {
    button: HTMLDivElement | any,
    children?: React.ReactNode[] | any
    visible: boolean,
    volume: number,
    onVolumeChanged(value: number): void;
    onVisibilityChanged(value: boolean): void;
}

export type VolumeConstrolState = {
    volume: number,
    visible: boolean
}

export class VolumeControl extends React.Component<VolumeControlProps, VolumeConstrolState> {
    content: HTMLElement;
    button: HTMLElement;
    bar: HTMLElement;
    barFul: HTMLElement;
    isDrag: boolean;
    timer: NodeJS.Timeout | null;
    constructor(props: VolumeControlProps) {
        super(props);
        this.state = {
            volume: props.volume,
            visible: props.visible
        }

        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseDownOutside = this.onMouseDownOutside.bind(this);
        this.onResize = this.onResize.bind(this);
        this.hide = this.hide.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);

        this.content = document.createElement('div');
        this.content.id = 'volume';
        this.content.className = 'fadeout';
        this.content.style.display = 'none';

        let div = document.createElement('div');
        div.id = 'barEmpty';
        this.bar = div;
        this.content.appendChild(div);

        div = document.createElement('div');
        div.id = 'barFull';
        this.barFul = div;
        this.content.appendChild(div);

        div = document.createElement('div');
        div.id = 'sliderBtn';
        this.content.appendChild(div);
        this.button = div;
        this.isDrag = false;
        this.timer = null;
    }
    componentDidMount(): void {
        if (modal == null) {
            return;
        }

        modal.appendChild(this.content);
        this.content.onmousedown = this.onMouseDown;
        //this.content.ontouchstart = this.onMouseDown;
        this.content.onmouseup = this.onMouseUp;
        //this.content.ontouchend = this.onMouseUp;
        this.content.onmousemove = this.onMouseMove;
        //this.content.ontouchmove = this.onMouseMove;
        var self = this;
        this.content.addEventListener('ontouchstart', function (e) {
            if (e instanceof TouchEvent) {
                self.onMouseDown(e)
            }
        }, { passive: true });

        this.content.addEventListener('ontouchend', function (e) {
            if (e instanceof TouchEvent) {
                self.onMouseUp(e)
            }
        }, { passive: true });

        this.content.addEventListener('mousemove', function (e) {
            if (e instanceof TouchEvent) {
                self.onMouseMove(e)
            }
        }, { passive: true });



        window.addEventListener("resize", this.onResize);
    }

    componentDidUpdate(prevProps: Readonly<VolumeControlProps>, prevState: Readonly<VolumeConstrolState>, snapshot?: any): void {
        let self = this;
        if (prevState.volume !== this.state.volume) {
            var theta = 1 - (this.volume / 100);
            this.button.style.top = (theta * 200) + 'px';
            this.barFul.style.top = this.button.style.top;
            this.barFul.style.height = ((1 - theta) * 200) + 'px';
            this.resetTimeout();
        }

        if (this.props.visible === this.state.visible) {
            return;
        }

        this.setState(() => {
            return { visible: this.props.visible }
        })

        if (this.props.visible) {
            setTimeout(() => {
                window.addEventListener("click", this.onMouseDownOutside);
                window.addEventListener("touchstart", this.onMouseDownOutside);
            }, 1000);
            this.timer = setTimeout(this.hide, 5000);

            var button = document.getElementById(media.mediaVolume);
            var rect: DOMRect | any = null;
            if (button != null) {
                rect = button.getBoundingClientRect();
            }

            if (rect != null) {
                this.content.style.top = (rect.top - 200) + 'px';
                if (document.body.clientWidth >= 448) {
                    this.content.style.left = (rect.left + 7) + 'px';
                }
                else if (document.body.clientWidth >= 336) {
                    this.content.style.left = rect.left + 'px';
                }
                else {
                    this.content.style.left = (rect.left - 9) + 'px';
                }
            }

            this.content.style.display = "block";
            this.content.className = 'fadein';
        }
        else {
            if (this.timer != null) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            this.content.className = 'fadeout';
            setTimeout(function () {
                self.content.style.display = 'none';
                self.content.className = 'volumHidden';
                self.timer = null;
            }, 500);

            window.removeEventListener("click", this.onMouseDownOutside);
            window.removeEventListener("touchstart", this.onMouseDownOutside);
        }
    }
    componentWillUnmount(): void {
        if (modal == null) {
            return;
        }

        modal.removeChild(this.content);
        this.bar.onmousedown = null;
        this.button.onmousedown = null;
        this.button.onmouseup = null;
        this.content.onmousemove = null;
        window.removeEventListener("resize", this.onResize);
    }

    hide() {
        this.props.onVisibilityChanged(false);
        this.timer = null;
    }

    resetTimeout(timeout: number = 5000) {
        if (this.timer != null) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        this.timer = setTimeout(this.hide, timeout);
    }

    onResize(e: UIEvent) {
        var value = this.volume;
        var increment = 0;
        if (value > 50) {
            increment = -1;
        }
        else {
            increment = 1;
        }

        this.volume = value + increment;
        this.volume = value;
        this.resetTimeout();
    }

    onMouseDown(e: MouseEvent | TouchEvent): void {
        this.isDrag = true;
        var bar: DOMRect = this.bar.getBoundingClientRect();
        var posY = this.getYPosition(e);
        var percent = 100 - ((posY - bar.top) / 200) * 100;
        this.volume = percent;
        this.resetTimeout();
    }

    onMouseUp(e: MouseEvent | TouchEvent): void {
        if (!this.isDrag) {
            return;
        }

        this.isDrag = false;
    }

    onMouseMove(e: MouseEvent | TouchEvent): void {
        if (!this.isDrag) {
            return;
        }

        var volume = this.volume;
        var bar: DOMRect = this.bar.getBoundingClientRect();
        var yPos: number = this.getYPosition(e);

        volume = 100 - (((yPos - bar.top) / 200) * 100);
        this.volume = volume;
        this.resetTimeout();
    }

    onMouseDownOutside(e: MouseEvent | TouchEvent) {
        var x: number | any;
        var y: number | any;
        if (e instanceof MouseEvent) {
            x = e.clientX;
            y = e.clientY;
        }

        if (e instanceof TouchEvent) {
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
        }

        if (x == null || y == null) {
            return;
        }

        var element = document.elementFromPoint(x, y);
        if (element == null) {
            return;
        }

        if (this.isVolumeElement(element.id)) {
            return;
        }

        this.props.onVisibilityChanged(false);
    }

    isVolumeElement(id: string): boolean {
        if (id == null || id === '') {
            return false;
        }

        return id === 'volume' || id === 'barEmpty' || id === 'barFull' || id === 'sliderBtn' || id === 'mediaVolume';
    }

    getYPosition(e: MouseEvent | TouchEvent): number {
        if (e instanceof MouseEvent) {
            return (e as MouseEvent).clientY;
        }

        if (e instanceof TouchEvent) {
            var touch = e as TouchEvent;
            if (touch.touches.length <= 0) {
                return 0;
            }

            return touch.touches[0].clientY;
        }
        return 0;
    }
    public get volume(): number {
        return this.state.volume;
    }
    public set volume(value: number) {
        value = clamp(value, 0, 100);
        if (value === this.volume) {
            return;
        }

        this.setState(() => {
            return { volume: value };
        });
        this.props.onVolumeChanged(value);
    }
    render(): JSX.Element {
        return ReactDOM.createPortal(
            this.props.children,
            this.content
        );
    }
}