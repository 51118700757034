import React from "react"
import "./TimeControl.css"

export type TimeControlProbs = {
    onSeek(percent: number): void;
}

export type TimeControlState = {
    totalTime: number;
    elapsed: number;
}

const clamp = (num: number, min: number, max: number): number => Math.min(Math.max(num, min), max);

export class TimeControl extends React.Component<TimeControlProbs, TimeControlState> {
    container: React.RefObject<HTMLDivElement>
    constructor(probs: TimeControlProbs) {
        super(probs);
        this.state = {
            totalTime: 0,
            elapsed: 0
        }

        this.onContainerClick = this.onContainerClick.bind(this);
        this.onContainerTouch = this.onContainerTouch.bind(this);
        this.container = React.createRef();       
    }

    componentDidMount(): void {
        tm.onElapsedChanged = (elapsed: number) => {
            this.setState({ elapsed: elapsed });
        }

        tm.onTotalTimeChanged = (totalTime: number) => {
            this.setState({ totalTime: totalTime });
        }
    }

    getTimeString(value: number): string {
        var minutes = Math.floor(value / 60) || 0;
        var seconds = value % 60 || 0;
        return minutes + ':' + (seconds < 10 ? '0' : '') + Math.round(seconds);
    }
    getProgress(): number {
        var progress = tm.progres();
        return progress * 100;
    }
    getProgressStyle(): React.CSSProperties {
        var progress = this.getProgress();
        if (this.container.current != null) {
            progress = Math.max(progress, (40 / this.container.current.offsetWidth) * 100);
        }

        return {
            width: progress + '%'
        }
    }
    onContainerClick(e: React.MouseEventHandler | any) {
        e.preventDefault();
        if (this.container.current == null) {
            return;
        }
        var percent = this.seek(e.clientX);
        this.props.onSeek(percent);
    }
    onContainerTouch(e: TouchEvent | any) {
        if (e instanceof TouchEvent && e.touches.length > 0) {
            var percent = this.seek(e.touches[0].clientX);
            this.props.onSeek(percent);
        }
    }
    seek(clientX: number): number {
        if (this.container.current == null) {
            return this.getProgress();
        }

        var rect: DOMRect = this.container.current.getBoundingClientRect();
        return ((clientX - rect.left) / rect.width) * 100;
    }
    render(): JSX.Element {
        const elapsed = this.getTimeString(this.state.elapsed);
        return (<div id="timeContainer" title={elapsed} ref={this.container} onClick={(e) => this.onContainerClick(e)}>
            <div id="timeLabel" className="timeLabel">{this.getTimeString(this.state.totalTime)}</div>
            <div id="emptyBar" onMouseDown={(e) => this.onContainerClick(e)} onTouchStart={(e) => this.onContainerTouch(e)}></div>
            <div id="progressBar" style={this.getProgressStyle()} onClick={(e) => this.onContainerClick(e)}>
                <div id="elapsedLabel" className="timeLabel">{elapsed}</div>
            </div>
        </div>)
    }
}

 
export interface ITimeManager {
    totalTime: number;
    elapsed: number;
    onSeek(percent: number): void;
    progres:() => number;
}

class TimeManager implements ITimeManager {
    private _elapsed: number
    private _totalTime: number
    onTotalTimeChanged?:(totalTime: number) => void;
    onElapsedChanged?:(elapsed: number) => void;
    constructor(totalTime: number, elapsed: number) {
        this._totalTime = totalTime;
        this._elapsed = elapsed;
    }

    public get totalTime(): number {
            return this._totalTime;
    }

    public set totalTime(value: number) {
        this._totalTime = value;
        if (this.onTotalTimeChanged != null) {
            this.onTotalTimeChanged(value);
        }
    }

    public get elapsed(): number {
        return this._elapsed;
    }

    public set elapsed(value: number) {
        this._elapsed = value;
       
        if (this.onElapsedChanged != null) {
            this.onElapsedChanged(value);
        }
    }

    onSeek(percent: number): void {
        this.elapsed = this.totalTime * percent;
    }

    progres ():  number
    {
        return clamp((this.elapsed / this.totalTime) || 0, 0, 1);    
    }
}

const tm = new TimeManager(0, 0);
export function GetTimeManager(): ITimeManager {
    return tm;
}