import { Howl } from "howler";
import React from "react"
import { LoadState, MediaState, random, RepeatState, SortTracks, Track, SortType, AUDIO_URL, DATA_URL } from "./common";
import MediaControl from "./MediaControl";
import { GetTimeManager, TimeControl } from "./TimeControl";
import { TitleBar } from "./TitleBar";
import { publish } from "./events";
import PlaylistControl from "./PlaylistControl";
import './MusicPlayer.css'
import DownloadControl from "./DownloadControl";
import {LyricsControl} from "./LyricsControl";
import PlaylistManager from "./Playalist";
import controlStyle from './MediaControl.module.css'

export type MusicPlayerProps = {

}

export type MusicPlayerState = {
    tracks: Track[] | any;
    index: number;
    volume: number;
    playlistLoadState: LoadState;
    mediaState: MediaState;
    playRandom: boolean;
    repeateMode: RepeatState;
    showPlaylist: boolean;
    showMenu: boolean;
    orderBy: SortType;
    lyrics: string;
    lyricsRef: number;
    isLoadingLyrics: boolean;
    incarnation: number;
}

export class MusicPlayer extends React.Component<MusicPlayerProps, MusicPlayerState> {
    _loadStarted: boolean;
    _music: Howl | any;
    _wantedMediaState: MediaState;
    _abortController: AbortController | null;
    _hasInteraction: boolean;
    resizeObserver: ResizeObserver | null;
    _forceUpdate: boolean;

    constructor(props: MusicPlayerProps) {
        super(props);
        this.state = {
            tracks: null,
            index: -1,
            volume: 100,
            playlistLoadState: LoadState.None,
            mediaState: MediaState.Stopped,
            playRandom: false,
            repeateMode: RepeatState.Repeat,
            showPlaylist: false,
            showMenu: false,
            orderBy: SortType.None,
            lyrics: '',
            lyricsRef: 0,
            isLoadingLyrics: false,
            incarnation: 0
        }

        this.toggleMenu = this.toggleMenu.bind(this);
        this.togglePlaylist = this.togglePlaylist.bind(this);
        this.setMediaState = this.setMediaState.bind(this);
        this.setRepeatState = this.setRepeatState.bind(this);
        this.setVolume = this.setVolume.bind(this);
        this.setProgress = this.setProgress.bind(this);
        this.setPlayrandom = this.setPlayrandom.bind(this);
        this.skip = this.skip.bind(this);
        this.onMediaPlayPause = this.onMediaPlayPause.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onSelectedTrackChanged = this.onSelectedTrackChanged.bind(this);
        this.getTracks = this.getTracks.bind(this);
        this.setOrderBy = this.setOrderBy.bind(this);
        this.onTracksChanged = this.onTracksChanged.bind(this)
        this.onResize = this.onResize.bind(this);

        this._loadStarted = false;
        this._wantedMediaState = MediaState.Stopped;
        this._abortController = null;
        this._hasInteraction = false;
        this.resizeObserver = null;
        this._forceUpdate = false;
    }

    public get Howl(): Howl | null {
        if (this._music instanceof Howl) {
            return this._music
        }

        return null;
    }

    public get tracks(): Track[] {
        return this.state.tracks;
    }

    private set tracks(value: Track[]) {
        if (value === null || value.length <= 0) {
            this.playlistLoadState = LoadState.Failed;
            return
        }

        let index = 0;
        if (this.state.tracks != null) {
            let track = this.state.tracks[this.state.index];
            for (let i = 0; i < value.length; i++) {
                if (value[i].id === track.id) {
                    index = i;
                    break;
                }
            }
        }

        this.setState(() => {
            return {
                tracks: value,
                playlistLoadState: LoadState.Success,
                index: index
            };
        })
    }
    public get index(): number {
        return this.state.index;
    }
    public set index(value: number) {
        if (this.tracks == null) {
            return;
        }
        if (value < 0 && this.elapsedTime >= 3) {
            this.elapsedTime = 0;
            if (this._music != null) {
                this._music.seek(0);
            }
            return;
        }

        if (value < 0) {
            value = this.tracks.length - 1;
        }
        else if (value >= this.tracks.length) {
            value = 0;
        }
        if (value === this.state.index) {
            this._forceUpdate = true;
        }

        this.setState(() => {
            return {
                index: value,
            };
        })
    }
    public get playlistLoadState(): LoadState {
        return this.state.playlistLoadState;
    }
    private set playlistLoadState(value: LoadState) {
        this.setState(() => {
            return { playlistLoadState: value }
        })
    }
    public get totalTime(): number {
        return this.Howl?.duration() || 0;
    }

    private set totalTime(value: number) {
        GetTimeManager().totalTime = value;
    }

    public get elapsedTime(): number {
        return this.Howl?.seek() || 0;
    }
    private set elapsedTime(value: number) {
        GetTimeManager().elapsed = value;
    }
    public get mediaState(): MediaState {
        return this.state.mediaState;
    }

    private set mediaState(value: MediaState) {
        if (value === this.state.mediaState) {
            return;
        }

        this._hasInteraction = true;
        this.setState(() => {
            return { mediaState: value };
        })
    }

    public get volume(): number {
        return this.state.volume;
    }

    private set volume(value: number) {
        this.setState(() => {
            return { volume: value };
        })
    }

    public get playRandom(): boolean {
        return this.state.playRandom;
    }

    private set playRandom(value: boolean) {
        this.setState(() => {
            return { playRandom: value };
        })
    }

    public get repeatMode(): RepeatState {
        return this.state.repeateMode;
    }

    private set repeatMode(value: RepeatState) {
        if (this.repeatMode === value) {
            return;
        }

        this.setState(() => {
            return { repeateMode: value }
        })
    }

    public get orderBy(): SortType {
        return this.state.orderBy;
    }

    private set orderBy(value: SortType) {
        if (value === this.orderBy) {
            return;
        }

        var tracks = this.state.tracks.slice();
        SortTracks(tracks, value);
        var selected = this.state.tracks[this.index];
        var index = -1;
        for (var i = 0; i < tracks.length; i++) {
            if (selected.name === tracks[i].name) {
                index = i;
                break;
            }
        }

        this.setState(() => {
            return {
                orderBy: value,
                tracks: tracks,
                index: index
            }
        })
    }
    public get lyrics(): string {
        return this.state.lyrics;
    }

    private set lyrics(value: string) {
        this.setState((old) => {
            return {
                lyrics: value,
                lyricsRef: old.lyricsRef + 1
            }
        })
    }

    public get IsLoadingLyrics(): boolean {
        return this.state.isLoadingLyrics;
    }

    private set IsLoadingLyrics(value: boolean) {
        this.setState(() => {
            return { isLoadingLyrics: value }
        })
    }

    componentDidMount(): void {
        if (!this._loadStarted) {
            this._loadStarted = true;
            this.playlistLoadState = LoadState.Loading;
            fetch(DATA_URL + 'tracks.json').then((res) => res.json()).then((tracks) => {
                var playlist: any = [];
                for (const key in tracks) {
                    if (tracks.hasOwnProperty(key)) {
                        playlist.push(tracks[key] as Track);
                    }
                }

                this.tracks = playlist;
                PlaylistManager().SetTracks(playlist);
                PlaylistManager().OnTracksChanged = this.onTracksChanged;
                const query = new URLSearchParams(window.location.search);
                const idValue = query.get('id');
                let name = query.get('name');
                let index: number = 0;
                if (idValue != null) {
                    let id: number = parseInt(idValue);
                    for (let i = 0; i < playlist.length; i++) {
                        if (playlist[i].id === id) {
                            index = i;
                            break;
                        }
                    }
                }
                else if (name != null) {
                    name = decodeURI(name);
                    name = name.replace('_', ' ').toLocaleLowerCase();
                    for (let i = 0; i < playlist.length; i++) {
                        if (playlist[i].name.toLocaleLowerCase() === name) {
                            index = i;
                            break;
                        }
                    }
                }

                this.setState(() => {
                    return {
                        showPlaylist: true,
                        showMenu: false,
                        index: index
                    }
                })
            })
        }

        window.addEventListener("keydown", this.handleKeyDown);
        this.resizeObserver = new ResizeObserver(this.onResize);
        this.resizeObserver.observe(document.body);
    }

    componentDidUpdate(prevProps: Readonly<MusicPlayerProps>, prevState: Readonly<MusicPlayerState>): void {
        if (this._forceUpdate || (prevState.index !== this.index && this.index >= 0)) {
            this._forceUpdate = false;
            if (prevState.orderBy === this.orderBy) {
                this.loadCurrentTrack();
            }
        }

        if (prevState.mediaState !== this.mediaState) {
            if (this._music == null && this.mediaState === MediaState.Playing && this._hasInteraction) {
                this.loadCurrentTrack();
            }

            if (this._music != null) {
                switch (this.mediaState) {
                    case MediaState.Stopped:
                        if (this._hasInteraction) {
                            this._music.stop();
                        }
                        break;

                    case MediaState.Loading:
                        break;

                    case MediaState.Playing:
                        if (this._hasInteraction && !this._music.playing()) {
                            this._music.play();
                        }

                        break;

                    case MediaState.Paused:
                        if (this._hasInteraction) {
                            this._music.pause();
                        }
                        break;
                }
            }
        }

        if (prevState.volume !== this.volume) {
            Howler.volume(this.volume / 100 || 100);
        }

        if (prevState.showPlaylist !== this.state.showPlaylist) {
            var button = document.getElementById('playlistButton');
            if (button != null) {
                if (this.state.showPlaylist) {
                    button.classList.add('closeButton');
                }
                else {
                    button.classList.remove('closeButton');
                }
            }
        }

        if (prevState.showMenu !== this.state.showMenu) {
            button = document.getElementById('menuButton');
            if (button != null) {
                if (this.state.showMenu) {
                    button.classList.add('closeButton');
                }
                else {
                    button.classList.remove('closeButton');
                }
            }
        }
    }
    componentWillUnmount(): void {
        window.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e: any) {
        switch (e.keyCode) {
            case 27: // Escape
                this.setState(() => {
                    return { showPlaylist: false }
                })
                break;

            case 32: // Space
                this.onMediaPlayPause(this.mediaState !== MediaState.Playing);
                break;

            case 179: // Media play pause.            
                if (this.mediaState === MediaState.Playing) {
                    this.mediaState = MediaState.Paused;
                }
                else {
                    this.mediaState = MediaState.Playing;
                }
                break;

            case 176: // MediaTrackNext
                this.skip(1);
                break;

            case 177: // MediaTrackPrevious
                this.skip(-1);
                break;

            case 178: //MediaStop
                this.mediaState = MediaState.Stopped;
                break;

            default:
                break;
        }
    }
    
    loadCurrentTrack(): void {
        if (this._music != null) {
            this._music.stop();
            this._music.unload();
        }

        var current: Track = this.selectedTrack();
        if (current == null) {
            return;
        }

        if (!this._hasInteraction) {
            if (current.id !== 1) {
                document.title = `bbb (${current.name})`;
            }
            return;
        }

        document.title = `bbb (${current.name})`;
        this.mediaState = MediaState.Loading;

        var self = this;
        var sound: Howl;
        var loaded = false;
        sound = this._music = new Howl({
            src: [AUDIO_URL + current.file + '.mp3'],
            html5: true,
            onplay: function () {
                requestAnimationFrame(self.step.bind(self));
                if (!loaded) {
                    self.setMediaSession(current);
                    loaded = true;
                }
            },
            onload: function () {
                self.totalTime = Math.round(sound.duration());
                self.mediaState = self._wantedMediaState;
            },
            onend: function () {
                if (self.repeatMode === RepeatState.RepeatOne) {
                    self.elapsedTime = 0;
                    sound.seek(0);
                    sound.play();
                }
                else if (self.index + 1 >= self.tracks.length && self.repeatMode !== RepeatState.Repeat) {
                    self.mediaState = MediaState.Stopped;
                }
                else {
                    self.skip(1);
                }
            },
            onpause: function () {

            },
            onstop: function () {

            },
            onseek: function () {
                requestAnimationFrame(self.step.bind(self));
            },
            onloaderror: function (soundID: number, error: unknown) {

            }
        });

        this.lyrics = '';
        this.IsLoadingLyrics = false;
        if (current.lyrics != null && current.lyrics !== '') {
            var url = DATA_URL + encodeURIComponent(current.lyrics) + '.txt';
            if (this._abortController != null) {
                this._abortController.abort();
            }

            this.IsLoadingLyrics = true;
            this._abortController = new AbortController();
            fetch(url, { method: 'get', signal: this._abortController.signal }).then((response) => response.text()).then((txt) => {
                this.lyrics = txt;
                this._abortController = null;
                this.IsLoadingLyrics = false;
            });
        }
    }

    setMediaSession(track: Track) {
        if ("mediaSession" in navigator) {
            navigator.mediaSession.metadata = null;
            navigator.mediaSession.metadata = new MediaMetadata({
                title: track.name,
                artist: track.original_artist,
                album: '',
                artwork: [
                    { src: 'media-logo.png', sizes: '512x512', type: 'image/png' },
                ]
            });

            navigator.mediaSession.setActionHandler("play", () => this.onMediaPlayPause(true));
            navigator.mediaSession.setActionHandler("pause", () => this.onMediaPlayPause(false));
            navigator.mediaSession.setActionHandler("nexttrack", () => this.skip(1));
            navigator.mediaSession.setActionHandler("previoustrack", () => this.skip(-1));
        }
    }
    selectedTrack(): Track | any {
        if (this.state.playlistLoadState !== LoadState.Success || this.index <= -1) {
            return null;
        }

        return this.state.tracks[this.state.index];
    }
    step(): void {
        if (this._music == null) {
            return;
        }

        var self = this;
        var seek = this._music.seek() || 0;
        var progress = ((seek / this._music.duration()) || 0);
        this.elapsedTime = progress * this._music.duration();
        if (this._music.playing()) {
            requestAnimationFrame(self.step.bind(self));
        }
    }

    toggleMenu(): void {
        var value = !this.state.showMenu;
        this.setState(() => {
            return {
                showMenu: value,
                showPlaylist: false
            };
        })
    }

    togglePlaylist(): void {
        var value = !this.state.showPlaylist;
        this.setState(() => {
            return {
                showPlaylist: value,
                showMenu: false
            }
        })
    }

    skip(value: number): void {
        if (this.playRandom) {
            this.index = random(0, this.tracks.length);
        }
        else if (value < 0 && this.elapsedTime > 3) {
            this.elapsedTime = 0;
            if (this._music != null) {
                this._music.seek(0);
            }
        }
        else {
            this.index = this.index + value;
        }
    }

    setMediaState(value: MediaState): void {
        this._wantedMediaState = value;
        this.mediaState = value;
    }

    setRepeatState(value: RepeatState): void {
        this.repeatMode = value;
    }

    setVolume(value: number): void {
        this.volume = value;
    }

    setProgress(value: number): void {
        if (this._music == null) {
            return;
        }

        const elapsed = this._music.duration() * value / 100;
        if (this._music != null) {
            this._music.seek(elapsed);
        }
        else {
            this.elapsedTime = elapsed;
        }
    }
    setPlayrandom(value: boolean) {
        this.playRandom = value;
    }
    getTrackName(): string {
        var track: Track = this.selectedTrack();
        if (track != null) {
            return track.name;
        }

        return 'none';
    }
    getTrackArtist(): string {
        var track: Track = this.selectedTrack();
        if (track != null) {
            return track.original_artist;
        }

        return 'none';
    }
    onMediaPlayPause(play: boolean): void {
        var state: MediaState;
        if (play) {
            state = MediaState.Playing;
        }
        else {
            state = MediaState.Paused;
        }

        this.mediaState = state;
        publish("playstateChanged", state);
    }

    onSelectedTrackChanged(value: Track): void {
        this._hasInteraction = true;
        for (var i = 0; i < this.tracks.length; i++) {
            if (value.name === this.tracks[i].name) {
                this.index = i;
                if (this.mediaState !== MediaState.Playing) {
                    let btn = document.getElementById(controlStyle.mediaPlay);
                    if (btn != null) {
                        btn.click();
                    }
                }
                if (this.state.showPlaylist) {
                    this.togglePlaylist()
                }
                break;
            }
        }
    }
    getTracks(): Track[] {
        return this.tracks;
    }
    getTrackCount(): number {
        return this.tracks != null ? this.tracks.length : 0;
    }
    setOrderBy(value: SortType) {
        this.orderBy = value;
    }
    onTracksChanged(tracks: Track[]): void {
        this.tracks = tracks;
    }
    onResize(): void {
        this.setState(() => {
            return { incarnation: this.state.incarnation + 1 };
        })
    }
    render(): JSX.Element {
        return (<div id="musicPlayer" className="container mx-auto">
            <TitleBar track={this.selectedTrack()} visible={this.selectedTrack() != null} onToggleMenu={() => this.toggleMenu()} onTogglePlaylist={() => this.togglePlaylist()}></TitleBar>
            <PlaylistControl
                key={this.getTrackCount()}
                onOrderChanged={(value) => this.setOrderBy(value)}
                onSelectedTrackChanged={(value) => this.onSelectedTrackChanged(value)}
                orderBy={PlaylistManager().Order}
                selectedIndex={this.index}
                visible={this.state.showPlaylist}
                getTracks={this.getTracks}
            ></PlaylistControl>
            <div id="logoContainer">
                <div id="bbbLogo"></div>
                <LyricsControl key={this.state.lyricsRef} visible={this.lyrics != null && this.lyrics !== ''} text={this.lyrics}></LyricsControl>
                <div id="loading" className={(this.IsLoadingLyrics ? '' : 'hidden')}></div>
            </div>
            <TimeControl onSeek={(value) => this.setProgress(value)}></TimeControl>
            <MediaControl mediaState={this.state.mediaState}
                onIndexChanged={(value) => this.skip(value)}
                onMediaStateChanged={(value) => this.setMediaState(value)}
                onRepeatStateChanged={(value) => this.setRepeatState(value)}
                onVolumeChanged={(value) => this.setVolume(value)}
                onPlayRandomChanged={(value) => this.setPlayrandom(value)}
                onTogglePlaylist={() => this.togglePlaylist()}
            ></MediaControl>
            <DownloadControl
                tracks={this.tracks}
                selectedTrack={this.selectedTrack()}
                visible={this.state.showMenu}
                onClosed={() => this.toggleMenu()}
            ></DownloadControl>
        </div>)
    }
} 