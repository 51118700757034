import React from 'react';

export type LyricsControlProps = {
    visible: boolean;
    text: string;
}

interface LyricsControlState {
    sizeIndex: number;
}

const style: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    backgroundColor: 'black',
    color: 'white'
}

function GetTextClasse(index: number): string {
    switch (index) {
        case 0:
            return 'text-center text-xs';

            case 1:
            return 'text-center text-sm';

            case 2:
            return 'text-center text-base';

            case 3:
            return 'text-center text-lg';

            case 4:
            return 'text-center text-xl';

            case 5:
            return 'text-center text-2xl';

            default:
            return 'text-center text-lg';
    }
}

export class LyricsControl extends React.Component<LyricsControlProps, LyricsControlState> {
    private _ref: React.RefObject<HTMLDivElement>;
    constructor(props: LyricsControlProps) {
        super(props);
        this._ref = React.createRef();

        this.state = ({ 
            sizeIndex: 4
        })
    }

    public get textClass(): string {
        return GetTextClasse(this.state.sizeIndex);   
    }

    componentDidMount(): void {
        const element = this._ref.current;
        if (element != null) {
            if (element.scrollHeight > element.clientHeight) {
                if (this.state.sizeIndex > 0) {
                    this.setState({ sizeIndex: this.state.sizeIndex - 1 });
                }           
             }
        }
    }

    componentDidUpdate(prevProps: Readonly<LyricsControlProps>, prevState: Readonly<LyricsControlState>, snapshot?: any): void {
        const element = this._ref.current;
        if (element != null) {
            if (element.scrollHeight > element.clientHeight) {
                if (this.state.sizeIndex > 0) {
                    this.setState({ sizeIndex: this.state.sizeIndex - 1 });
                }
            }
        }
    }

       render() {
        return <div ref={this._ref} className='overflow-auto no-scrollbar' style={style} hidden={!this.props.visible}>
            <pre className={this.textClass}>{this.props.text}</pre>            
            </div>
    }
}

// export function LyricsControl(props: LyricsControlProps) {
//     const eRef: React.RefObject<HTMLDivElement> = React.createRef();    
//     const [updated, setUpdated] = useState(false);
//     useEffect(() => {

//         if (updated) {
//             return;
//         }

//         setUpdated(true);
        
//         var element = eRef.current;
//         if (element == null) {
//             return;
//         }

//         element.style.display = props.visible ? 'block' : 'none'
//         if (!props.visible) {
//             return;
//         }
//         const container = element.parentElement;
//         if (container === null) {
//             return;
//         }

//         var lines = props.text.split(/\r\n|\r|\n/);
//         var width = 0;
//         let max = 20;
//         let min = 8;
//         if (document.body.clientWidth <= 288) {
//             max = 16;
//             min = 6;
//         }
//         else if (document.body.clientWidth <= 384) {
//             max = 18;
//             min = 10;
//         }

//         lines.forEach(line => {
//             width = Math.max(width, line.length);
//         });

//         width *= 0.75;
//         let cw = container.clientWidth - 10;
//         let ch = container.clientHeight - 10;
//         min = Math.max(Math.min(Math.round(cw / max), Math.round(ch / lines.length)), min);
//         let size = min;
//         for (var i = max; i >= 6; i--) {
//             let w = cw / i;
//             let h = ch / (lines.length * i);

//             if (w >= 1.25 && h >= 1.25) {
//                 size = i;
//                 break;
//             };
//         }
        
//         element.style.fontSize = size + "px";
//         element.innerHTML = '<pre style="margin: 0;"><center style="margin: 5px;">' + props.text + '</center></pre>'
//     });

//     const ro = new ResizeObserver(() => { setUpdated(false)});
//     ro.observe(document.body);
//     return <div ref={eRef} className={classes.lyrics} style={style}></div>
// }